import React from 'react'
import { useHistory } from 'react-router-dom';
import './footer.css';

export default function Footer() {
  const history = useHistory();

  return (
    <div>
      <div class="footer-content">
        <div class="container  py-5">
          <div class="d-sm-flex">
            <div class="col-lg-3">
              {/* <a class="navbar-brand logo-image" href="/"><img className='logo' src="images/policicue_logo.svg" alt="alternative" /></a> */}
              <h6 className='h6-text-a'>About PoliciCue</h6>
              <p class="p-small-footer">
                We, at PoliciCue, are a team of industry professionals having collective insurance industry experience of more than 75 years. Our experts review/understand the need and then connect you with best suited insurance policies and plans.
              </p>

              <p class="p-small-footer">
                We delight our customers with best of post-sale services including claims support through our focused and experienced team of industry professionals.
              </p>
            </div>
            <div class="col-lg-3">
              <h6>Products</h6>
              <ul class="list-unstyled text-content-3">
                {/* <li><a class="a-text" href="/RetailDashboard">About us</a></li> */}
                <li className='li-item'><div class="a-text" style={{cursor:"pointer", fontSize:"12px"}} onClick={() => history.push("/HealthInsurance")}>Health Insurance</div></li>
                <li className='li-item'><div class="a-text" style={{cursor:"pointer", fontSize:"12px"}} onClick={() => history.push("/carstep1")}>Car Insurance</div></li>
                <li className='li-item'><div class="a-text" style={{cursor:"pointer", fontSize:"12px"}} onClick={() => history.push("/Two-wheeler")}>Two-wheeler Insurance</div></li>
                <li className='li-item'><div class="a-text" style={{cursor:"pointer", fontSize:"12px"}} onClick={() => history.push("/LifeInsurance")}>Term Insurance</div></li>
                <li className='li-item'><div class="a-text" style={{cursor:"pointer", fontSize:"12px"}} onClick={() => history.push("/cattleInsurance")}>Cattle Insurance</div></li>
                <li className='li-item'><div class="a-text" style={{cursor:"pointer", fontSize:"12px"}} onClick={() => history.push("/OtherInsurance")}>Other Insurance</div></li>
              </ul>
            </div>
            <div class="col-lg-3">
              <h6>Useful Links</h6>
              <ul class="list-unstyled text-content-3">
                <li className='li-item'><a class="a-text" href="#/LegalAdminPolicies?0#" >Privacy Policy</a></li>
                <li className='li-item'><a class="a-text" href="#/LegalAdminPolicies?1#">Terms of Usage</a></li>
                {/* <li className='li-item'><a class="a-text" href="/LegalAdminPolicies" onClick={() => history.push("/LegalAdminPolicies")}>Code of Conduct</a></li> */}
                {/* <li className='li-item'><a class="a-text" href="/LegalAdminPolicies" onClick={() => history.push("/LegalAdminPolicies")}>Grievance Redressal</a></li> */}
                <li className='li-item'><a class="a-text" href="#/LegalAdminPolicies?2#" >Disclaimer</a></li>

                {/* <li><a class="a-text" href="/AgentDashboard">More Blogs</a></li> */}
                {/* <li><a class="a-text" href="/superadmin">Official Account Login</a></li> */}
              </ul>
            </div>
            <div class="col-lg-3 footer-links">
              <h6>Get In Touch</h6>
              <ul class="list-unstyled text-content-4">
                <li className='li-item'><img className="img-space" src="images/footer/building.png" alt="alternative" /> <a class="a-text" href="#" style={{ fontWeight: 'bold' }}>PoliciCue Insurance Brokers Private Limited.</a> </li>
                <li className='li-item'> <img className="img-space" src="images/footer/location.png" alt="alternative" /><a class="a-text" href="#" style={{marginLeft:"5px"}}>A-42/6,Sector-62 Noida Gautam Buddha Nagar UP 201301 IN</a> </li>
                {/* <li className='li-item'> <img className="img-space" src="" alt="" /><a class="a-text" href="#"> &nbsp; &nbsp;&nbsp; &nbsp; 201301 IN</a> </li> */}
                <li className='li-item'> <img className="img-space" src="images/footer/contact-mail.png" alt="alternative" /><a class="a-text" href="mailto:info@policicue.com">info@policicue.com</a> </li>
                <li className='li-item'><img className="img-space" src="images/footer/site.png" alt="alternative" /> <a class="a-text" href="#">www.policicue.com</a> </li>
                <li className='li-item'>
                  <div className='social-links'>
                    <a class="a-text" href="https://www.facebook.com/PoliciCue" target="_blank">
                      <img className="img-space" src="images/footer/fb.png" alt="alternative" />
                    </a>
                    <a class="a-text" href="https://www.instagram.com/policicue/" target="_blank">
                      <img className="img-space" src="images/footer/instagram.png" alt="alternative" />
                    </a>
                    <a class="a-text" href="https://www.linkedin.com/company/policicue/" target="_blank">
                      <img className="img-space" src="images/footer/linkedin.png" alt="alternative" />
                    </a>
                  </div>
                </li>

              </ul>
            </div>
          </div>
          <div className='col-12'>
            <div className='licence-container m-0'>
              {/* <div className='row'> */}
                  <div className='col-md-6 col-sm-12'>
              <div className='licence-block'>
                <h6 className='h6-text h6-size ml-0'>Licensed By :</h6><a href='#' onClick={() => history.push({
                  pathname: '/LegalAdminPolicies',
                  search: '3',
                })}
                > <img src="images/footer/irdai-logo.png" className='ml-3' alt="alternative" /></a>
              </div>
                  </div>
                  <div className='col-md-6 col-sm-12 text-left text-md-right mr-3'>
              <span className='rights-content'>
                © PoliciCue Insurance Brokers Pvt. Ltd. All Rights Reserved
              </span></div>
              </div>
            {/* </div> */}
          </div>

          <div className='col-12'>
            <div className='footer-note-block'>
              <span className='footer-note'>
                Insurance is the subject matter of Solicitation. For more details on risk factors, associate terms and conditions and exclusions, please read the product brochure before concluding a sale | PoliciCue Insurance Brokers Private Limited | Registration No: 794 | Broker Code: IRDAI/DB910/2021, Validity:06/01/2025 to 05/01/2028, Category-Direct Broker | CIN No- U66000UP2021PTC147086 | Email us at info@policicue.com  | | A-42/6,Sector-62 Noida Gautam Buddha Nagar UP 201301 IN
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
